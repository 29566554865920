<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    title="Approve And Release Withdrawal Request"
    :button2-text="btnLabel"
    @button2="save"
    :main-btn-disabled="!canWithdraw"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text v-if="item">
          <v-row dense>
            <v-col class="fw-700 secondary--text" cols="5">
              Request By :
            </v-col>
            <v-col cols="7" class="primary--text fw-500 fs-18">
              {{ item.user.fullname | ucwords }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="fw-700 secondary--text" cols="5"> Company : </v-col>
            <v-col cols="7" class="primary--text fw-500 fs-18">
              {{ item.company.name }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="fw-700 secondary--text" cols="5">
              Current User Balance :
            </v-col>
            <v-col
              cols="7"
              class="fw-500 fs-18"
              :class="canWithdraw ? 'success--text' : 'error--text'"
            >
              {{ user_current_available_balance | money }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="fw-700 secondary--text" cols="5">
              Withdraw Amount :
            </v-col>
            <v-col cols="7" class="primary--text fw-500 fs-18">
              {{ Math.abs(item.amount) | money }}
            </v-col>
            <v-col class="fw-700 secondary--text" cols="5">
              Withdrawal Fee :
            </v-col>
            <v-col cols="7" class="primary--text fw-500 fs-18" cl>
              {{ Math.abs(item.fee) | money }}
            </v-col>
            <v-col class="fw-700 secondary--text" cols="5">
              Amount To Transfer :
            </v-col>
            <v-col cols="7" class="primary--text fw-500 fs-18">
              {{ Math.abs(item.total_amount) | money }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="fw-700 secondary--text" cols="5">
              Destination :
            </v-col>
            <v-col cols="7" class="primary--text fw-500 fs-18">
              {{ item.destination | ucwords }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <label class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="release"
                  :true-value="true"
                  :false-value="false"
                ></v-checkbox>
                <span class="fw-700 ml-2 primary--text">
                  Approve and release requested amount?
                </span>
              </label>
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 secondary--text">Remarks/Notes</label>
              <v-textarea
                hide-details="auto"
                dense
                class="fullwidth"
                outlined
                v-model="remarks"
                auto-grow
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import request from "@/services/axios_instance";
export default {
  name: "ApprovedAndReleaseWithdrawal",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
  },
  data: () => ({
    dialog: false,
    user_current_available_balance: 0.0,
    release: false,
    remarks: "",
  }),
  computed: {
    canWithdraw() {
      return this.item &&
        this.user_current_available_balance >= Math.abs(this.item.amount)
        ? true
        : false;
    },
    btnLabel() {
      return this.release ? "Approve And Release Request" : "Only Approve Request";
    },
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
    item: {
      handler: function (val) {
        if (val) {
          this.getAvailableBalance(val.user_id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    save() {
      this.$emit("submit", {
        id: this.item.id,
        release: this.release,
        remarks: this.remarks,
      });
    },
    getAvailableBalance(user_id) {
      request.get(`api/wallet/${user_id}`).then(({ data }) => {
        this.user_current_available_balance = Number(
          data.data.balance_available
        );
      });
    },
  },
};
</script>
 <style>
</style>