import { config_utils } from '@/services/configs/config_utils'
import request from '@/services/axios_instance'
import Transactions from '@/modules/AdminArea/PaymentGateway/components/Transactions.vue'
import WithdrawalRequest from '@/modules/AdminArea/PaymentGateway/components/WithdrawalRequest.vue'
import PlanDialog from '@/modules/AdminArea/PaymentGateway/PlanDialog.vue'
import { fetch_services } from '@/services/fetch/fetch_services'

export default {
    name: 'Payments',
    mixins: [config_utils, fetch_services],
    components: {
        PlanDialog,
        Transactions,
        WithdrawalRequest
    },
    data: () => ({
        tabs: 'app_plans',
        activePlan: null,
        paths: [
            { text: 'Admin', disabled: false, router_name: 'admin-dashboard' },
            { text: 'Payments', disabled: false, router_name: 'admin-payments' }
        ],
        stripe_configs: {
            devMode: true,
            enable: true
        },
        from_app_plans: [],
        from_stripe_plans: [],
        plan: {
            id: null,
            nickname: null,
            short_description: null,
            long_description: null,
            amount: null,
            currency: 'usd',
            discount: null
        },
        footer_props: {
            showFirstLastPage: false
        },
        editting: false,
        adding: false,
        submitting: false,
        stripe_configs_btn: false,
        open_plan_dialog: false
    }),
    mounted() {
        this.$event.$emit('path-change', this.paths)
            // this.getAppPlans()
        this.all()
    },
    methods: {
        cancel() {
            this.adding = this.editting = false
            this.clear()
        },
        getAppPlans() {
            request
                .get(`api/upgrade/plans/apps?feature_type=main`)
                .then(({ data }) => {
                    this.from_app_plans = data
                })
        },
        save_stripe_configs() {
            this.sendRequest('stripe_configs', {
                key: 'stripe_configs',
                value: this.stripe_configs,
                type: 'object'
            })
        },
        editPlan(item) {
            this.activePlan = item
            this.open_plan_dialog = true
        },
        handleSave(payload) {
            request.post(`api/upgrade/plans/apps`, payload).then(({ data }) => {
                this.from_app_plans.push(data)
                this.open_plan_dialog = false
                this.appSnackbar('New subscription plan added')
            })
        },
        handleUpdate(payload) {
            request
                .put(`api/upgrade/plans/apps/${this.activePlan.id}`, payload)
                .then(({ data }) => {
                    let index = this.from_app_plans.findIndex((i) => i.id === data.id)
                    if (~index) {
                        this.from_app_plans.splice(index, 1, data)
                        this.appSnackbar('Subscription plan updated')
                        this.activePlan = null
                    }
                    this.open_plan_dialog = false
                })
        },
        updatePlanStatus(plan) {
            plan.hover = true
            let new_status = plan.status === 'active' ? 'inactive' : 'active'
            request
                .put(`api/upgrade/plans/apps/${plan.id}/status`, { status: new_status })
                .then(({ data }) => {
                    let index = this.from_app_plans.findIndex((i) => i.id === plan.id)
                    if (~index) {
                        this.from_app_plans[index].status = new_status
                        this.appSnackbar('Subscription plan updated')
                    }
                })
                .finally(() => {
                    plan.hover = false
                })
        }
    }
}