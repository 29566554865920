<template>
  <CustomDialog
    extra-body-class="px-5"
    :title="title"
    @button1="close"
    :max-width="800"
    ref="dialog"
    :open.sync="open"
    @button2="save"
    :main-btn-disabled="!valid"
    :button2-text="item ? `Update Plan` : `Save Plan`"
  >
    <template slot="content">
      <v-row dense>
        <v-col md="12">
          <v-form v-model="valid">
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="plan.name"
                  label="Plan Name:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Create below or select from Stripe"
                  item-value="id"
                  v-model="selected"
                  return-object
                  item-text="nickname"
                  hide-details="auto"
                  :items="available_stripe_plans"
                >
                  <template v-slot:item="{ item: myItem }">
                    <v-list-item dense @click="selected = myItem">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ myItem.nickname }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="caption">
                          Live mode : {{ myItem.livemode.toString() }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-textarea
              v-model="plan.description"
              label="Plan Description:"
              auto-grow
              rows="2"
              :rules="requiredRules"
              placeholder="Required"
              hide-details="auto"
            ></v-textarea>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="plan.product_id"
                  label="Product ID:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="plan.price_id"
                  label="Price ID:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="plan.amount_decimal"
                  @input="updateAmount"
                  label="Plan Amount In Decimal:"
                  :rules="[requiredRule(), numericRule(), numberOnlyRules()]"
                  placeholder="Required"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="plan.amount"
                  @input="updateAmountDecimal"
                  label="Normalize Plan Amount"
                  :rules="[requiredRule(), numericRule(), numberDotOnlyRules()]"
                  placeholder="Required"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  :items="currency_codes"
                  v-model="plan.currency"
                  label="Currency:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="['Stripe']"
                  v-model="plan.source"
                  label="Source:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                  v-model="plan.has_trial"
                  hide-details="auto"
                  :label="`${
                    plan.has_trial ? 'Disable plan trial' : 'Enable plan trial'
                  }`"
                ></v-switch>
              </v-col>
              <v-col>
                <v-text-field
                  v-show="plan.has_trial"
                  label="Number of trial days:"
                  type="number"
                  :min="1"
                  :max="30"
                  :rules="[numericRule(), minRule(1), maxRule(30)]"
                  :placeholder="`${
                    plan.has_trial ? 'Required' : 'Not required'
                  }`"
                  v-model="plan.trial_days"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  v-model="plan.interval_type"
                  :items="['month', 'year', 'one-time']"
                  label="Interval:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="plan.feature_type"
                  :items="['main', 'module', 'plugin']"
                  label="Feature Type:"
                  :rules="requiredRules"
                  placeholder="Required"
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from '@/services/axios_instance'

export default {
  name: 'PlanDialog',
  components: { CustomDialog },
  props: {
    dialog: { type: Boolean, default: false },
    item: Object,
    existing: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    currencyCodes: [
      'AED',
      'AFN',
      'ALL',
      'AMD',
      'ANG',
      'AOA',
      'ARS',
      'AUD',
      'AWG',
      'AZN',
      'BAM',
      'BBD',
      'BDT',
      'BGN',
      'BHD',
      'BIF',
      'BMD',
      'BND',
      'BOB',
      'BOV',
      'BRL',
      'BSD',
      'BTN',
      'BWP',
      'BYR',
      'BZD',
      'CAD',
      'CDF',
      'CHE',
      'CHF',
      'CHW',
      'CLF',
      'CLP',
      'CNY',
      'COP',
      'COU',
      'CRC',
      'CUC',
      'CUP',
      'CVE',
      'CZK',
      'DJF',
      'DKK',
      'DOP',
      'DZD',
      'EGP',
      'ERN',
      'ETB',
      'EUR',
      'FJD',
      'FKP',
      'GBP',
      'GEL',
      'GHS',
      'GIP',
      'GMD',
      'GNF',
      'GTQ',
      'GYD',
      'HKD',
      'HNL',
      'HRK',
      'HTG',
      'HUF',
      'IDR',
      'ILS',
      'INR',
      'IQD',
      'IRR',
      'ISK',
      'JMD',
      'JOD',
      'JPY',
      'KES',
      'KGS',
      'KHR',
      'KMF',
      'KPW',
      'KRW',
      'KWD',
      'KYD',
      'KZT',
      'LAK',
      'LBP',
      'LKR',
      'LRD',
      'LSL',
      'LTL',
      'LVL',
      'LYD',
      'MAD',
      'MDL',
      'MGA',
      'MKD',
      'MMK',
      'MNT',
      'MOP',
      'MRO',
      'MUR',
      'MVR',
      'MWK',
      'MXN',
      'MXV',
      'MYR',
      'MZN',
      'NAD',
      'NGN',
      'NIO',
      'NOK',
      'NPR',
      'NZD',
      'OMR',
      'PAB',
      'PEN',
      'PGK',
      'PHP',
      'PKR',
      'PLN',
      'PYG',
      'QAR',
      'RON',
      'RSD',
      'RUB',
      'RWF',
      'SAR',
      'SBD',
      'SCR',
      'SDG',
      'SEK',
      'SGD',
      'SHP',
      'SLL',
      'SOS',
      'SRD',
      'SSP',
      'STD',
      'SYP',
      'SZL',
      'THB',
      'TJS',
      'TMT',
      'TND',
      'TOP',
      'TRY',
      'TTD',
      'TWD',
      'TZS',
      'UAH',
      'UGX',
      'USD',
      'USN',
      'USS',
      'UYI',
      'UYU',
      'UZS',
      'VEF',
      'VND',
      'VUV',
      'WST',
      'XAF',
      'XAG',
      'XAU',
      'XBA',
      'XBB',
      'XBC',
      'XBD',
      'XCD',
      'XDR',
      'XFU',
      'XOF',
      'XPD',
      'XPF',
      'XPT',
      'XTS',
      'XXX',
      'YER',
      'ZAR',
      'ZMW'
    ],
    valid: false,
    from_stripe_plans: [],
    open: false,
    selected: null,
    plan: {
      live_mode: false,
      name: null,
      product_id: null,
      price_id: null,
      amount: null,
      amount_decimal: null,
      description: null,
      has_trial: false,
      trial_days: 1,
      source: 'Stripe',
      interval_type: 'month',
      currency: 'usd',
      feature_type: 'main'
    }
  }),
  computed: {
    title() {
      return this.item ? 'Edit Plan' : 'Create Plan'
    },
    currency_codes() {
      return this.currencyCodes.map((i) => {
        return i.toLowerCase()
      })
    },
    available_stripe_plans() {
      let existing_plan_id = this.existing.map((i) => {
        return i.price_id
      })
      return this.from_stripe_plans.filter(
        (i) => !existing_plan_id.includes(i.id) && i.active
      )
    }
  },
  watch: {
    dialog(val) {
      this.open = val
      if (val && !this.item) {
        this.resetPlan()
        this.selected = null
      }
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    selected: {
      handler: function (val) {
        this.mapStripe(val)
      },
      immediate: true,
      deep: true
    },
    item: {
      handler: function (val) {
        this.mapItem(val)
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getStripePlans()
  },
  methods: {
    close() {
      this.open = false
    },
    save() {
      if (this.plan) this.$emit(this.item ? 'update' : 'save', this.plan)
    },
    updateAmount(value) {
      this.plan.amount = (parseFloat(value) * 0.01).toFixed(2)
    },
    updateAmountDecimal(value) {
      this.plan.amount_decimal = parseInt(Math.floor(parseFloat(value) * 100))
    },
    getStripePlans() {
      this.loading = true
      request
        .get(`api/stripe/plans`)
        .then(({ data }) => {
          this.from_stripe_plans = data.data
        })
        .finally(() => (this.loading = false))
    },
    resetPlan() {
      this.plan = {
        name: null,
        product_id: null,
        price_id: null,
        amount: null,
        amount_decimal: null,
        description: null,
        has_trial: false,
        trial_days: 0,
        source: 'Stripe',
        interval_type: 'month',
        currency: 'usd',
        live_mode: false,
        feature_type: 'main'
      }
    },
    mapStripe(val) {
      if (!val) {
        this.resetPlan()
      } else {
        this.plan = {
          name: val.nickname,
          product_id: val.product,
          price_id: val.id,
          amount: null,
          amount_decimal: val.amount_decimal,
          description: val.metadata.description || null,
          has_trial: val.trial_period_days > 0,
          trial_days: val.trial_period_days || 1,
          source: 'Stripe',
          interval_type: val.interval || 'month',
          currency: val.currency || 'usd',
          live_mode: val.live_mode || false,
          feature_type: val.feature_type
        }
        this.updateAmount(val.amount_decimal)
      }
    },
    mapItem(val) {
      if (!val) {
        this.resetPlan()
      } else {
        this.plan = {
          name: val.name,
          product_id: val.product_id,
          price_id: val.price_id,
          amount: val.plan_amount,
          amount_decimal: val.plan_amount_decimal,
          description: val.description,
          has_trial: val.has_trial,
          trial_days: val.trial_days,
          source: val.source,
          interval_type: val.interval_type,
          currency: val.currency,
          live_mode: val.live_mode,
          feature_type: 'main'
        }
      }
    }
  }
}
</script>

<style scoped></style>
