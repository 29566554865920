<template>
  <v-card flat>
    <PageLoader v-if="processing">
      <strong class="my-2 primary--text">Processing... Please wait...</strong>
    </PageLoader>
    <v-card-title v-if="!processing">
      <v-row dense align="center" justify="center" class="my-2 fullwidth">
        <v-sheet
          height="100"
          color="success"
          class="
            rounded-10
            pa-5
            d-flex
            flex-column
            mx-1
            align-center
            justify-center
          "
        >
          <span class="fs-13 fw-500 white--text">
            Platform Available Balance:
          </span>
          <span class="white--text fw-900 fs-25">
            {{ platform_balance_available | money }}
          </span>
        </v-sheet>
        <v-sheet
          height="100"
          color="secondary"
          class="
            rounded-10
            pa-5
            d-flex
            flex-column
            mx-1
            align-center
            justify-center
          "
        >
          <span class="fs-13 fw-500 white--text">
            Platform Connect Reserved Balance:
          </span>
          <span class="white--text fw-900 fs-25">
            {{ platform_balance_connect_reserved | money }}
          </span>
        </v-sheet>
        <v-sheet
          height="100"
          color="accent"
          class="
            rounded-10
            pa-5
            d-flex
            flex-column
            mx-1
            align-center
            justify-center
          "
        >
          <span class="fs-13 fw-500 white--text">
            Platform Pending Balance:
          </span>
          <span class="white--text fw-900 fs-25">
            {{ platform_balance_pending | money }}
          </span>
        </v-sheet>
      </v-row>
      <v-row dense align="center" justify="center">
        <v-btn-toggle dense mandatory v-model="filter">
          <v-btn
            active-class="primary light--text"
            color="transparent"
            depressed
            dense
            class="text-none"
            value="all"
          >
            All
          </v-btn>
          <v-btn
            active-class="primary light--text"
            color="transparent"
            depressed
            dense
            class="text-none"
            value="pending"
          >
            Pending
          </v-btn>
          <v-btn
            active-class="primary light--text"
            color="transparent"
            depressed
            dense
            class="text-none"
            value="approved"
          >
            Approved
          </v-btn>
          <v-btn
            active-class="primary light--text"
            color="transparent"
            depressed
            dense
            class="text-none"
            value="cancelled"
          >
            Cancelled
          </v-btn>
          <v-btn
            active-class="primary light--text"
            color="transparent"
            depressed
            dense
            class="text-none"
            value="released"
          >
            Released
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!processing">
      <v-data-table
        :headers="headers"
        :disable-pagination="true"
        :items="items"
        :loading="loading_items"
        loader-height="10"
        hide-default-footer
      >
        <template v-slot:[`item.user`]="{ value }">
          <Avatar :user="value"></Avatar>
        </template>
        <template v-slot:[`item.destination`]="{ value }">
          {{ value | ucwords }}
        </template>
        <template v-slot:[`item.status`]="{ value }">
          <span :class="`${value}-text`">{{ value | ucwords }}</span>
        </template>
        <template v-slot:[`item.created_at`]="{ value }">
          {{ value | format('lll') }}
        </template>
        <template v-slot:[`item.remarks`]="{ value }">
          <HelpToolTip
            icon="mdi-note-text-outline"
            v-if="value"
            :text="value"
          ></HelpToolTip>
          <span v-else class="secondary--text">No remarks</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <HelpToolTip
            icon-class="mx-1"
            icon="mdi-eye-circle-outline"
            text="View Transaction"
            @click:icon="viewTransaction(item)"
          ></HelpToolTip>
          <HelpToolTip
            icon-class="mx-1"
            icon-color="error"
            v-if="item.status === 'pending'"
            icon="mdi-clipboard-alert"
            text="Reject Withdrawal Request"
            @click:icon="rejectTransaction(item)"
          ></HelpToolTip>
          <HelpToolTip
            icon-class="mx-1"
            v-if="item.status === 'pending'"
            icon="mdi-clipboard-check"
            text="Approve and Release Withdrawal Request"
            @click:icon="approveAndReleaseTransaction(item)"
          ></HelpToolTip>
        </template>
        <template v-slot:footer>
          <v-row dense class="pa-1">
            <v-spacer></v-spacer>
            <v-btn
              text
              class="text-none mt-2"
              outlined
              :disabled="!next_page_url || loading_more_items"
              :loading="loading_more_items"
              small
              @click="loadMoreItems"
            >
              Load more
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>

    <CreditDialog
      :dialog.sync="open_credit_dialog"
      @save="handleCreditSave"
    ></CreditDialog>
    <CreditView
      :dialog.sync="open_view_credit_dialog"
      :item="activeItem"
    ></CreditView>

    <RejectWithdrawal
      v-model="reject_dialog"
      :item="activeItem"
      @submit="handleRejectWithdrawal"
    ></RejectWithdrawal>

    <ApprovedAndReleaseWithdrawal
      v-model="release_dialog"
      :item="activeItem"
      @submit="handleReleaseApproveWithdrawal"
    ></ApprovedAndReleaseWithdrawal>
  </v-card>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import CreditDialog from '@/modules/AdminArea/PaymentGateway/components/CreditDialog.vue'
import CreditView from '@/modules/AdminArea/PaymentGateway/components/CreditView.vue'
import RejectWithdrawal from './RejectWithdrawal.vue'
import ApprovedAndReleaseWithdrawal from './ApprovedAndReleaseWithdrawal.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Transactions',
  mixins: [fetch_services],
  components: {
    CreditDialog,
    CreditView,
    RejectWithdrawal,
    ApprovedAndReleaseWithdrawal
  },
  created() {
    this.getTransactions()
    this.getPlatformBalance()
  },
  computed: {
    ...mapGetters('wallet', [
      'fetching_platform_balance',
      'platform_balance_available',
      'platform_balance_connect_reserved',
      'platform_balance_pending'
    ])
  },
  watch: {
    filter: {
      handler: function (val) {
        this.getTransactions()
      },
      immediate: true
    }
  },
  data: () => ({
    filter: 'all',
    open_credit_dialog: false,
    open_view_credit_dialog: false,
    reject_dialog: false,
    release_dialog: false,
    processing: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Destination', value: 'destination' },
      { text: 'Request By', value: 'user' },
      { text: 'Status', value: 'status' },
      { text: 'Amount', value: 'amount' },
      { text: 'Date', value: 'created_at' },
      { text: 'Notes', value: 'remarks' },
      { text: 'Action', value: 'actions' }
    ]
  }),
  methods: {
    ...mapActions('wallet', ['getPlatformBalance']),
    handleCreditSave(payload) {
      this.addItem(
        `api/admin/transactions`,
        payload,
        'Successfully added transaction entry',
        () => {
          this.open_credit_dialog = false
        },
        null
      )
    },
    handleRejectWithdrawal(payload) {
      this.processing = true
      request
        .put(`api/admin/withdrawal-request/${payload.id}/reject`, payload)
        .then(({ data }) => {
          this.replaceItem(data, 'Withdrawal request rejected')
        })
        .finally(() => {
          this.processing = false
          this.reject_dialog = false
        })
    },
    handleReleaseApproveWithdrawal(payload) {
      this.processing = true
      request
        .put(
          `api/admin/withdrawal-request/${payload.id}/approve-release`,
          payload
        )
        .then(({ data }) => {
          this.replaceItem(data, 'Withdrawal request updated')
          this.getPlatformBalance()
        })
        .catch(({ data }) => {
          this.appSnackbar(data, 'error')
        })
        .finally(() => {
          this.processing = false
          this.release_dialog = false
        })
    },
    getTransactions() {
      this.loadItems(`api/admin/withdrawal-request?filter=${this.filter}`, true)
    },
    viewTransaction(item) {
      this.activeItem = item
      this.open_view_credit_dialog = true
    },
    rejectTransaction(item) {
      this.activeItem = item
      this.reject_dialog = true
    },
    approveAndReleaseTransaction(item) {
      this.activeItem = item
      this.release_dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.cancelled-text {
  color: $textDark-3 !important;
}
.pending-text {
  color: $urgent !important;
}
.approved-text {
  color: $completed !important;
}
.released-text {
  color: $completed !important;
}
</style>
