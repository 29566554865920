<template>
  <CustomDialog
    title="View Transaction"
    button1-text="Close"
    :open.sync="open"
    :has-button2="false"
    @button1="close"
  >
    <template v-slot:content>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">ID:</v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.id }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Amount:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ Math.abs(info.amount) | money }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">Fee:</v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.fee | money }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Total Amount:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ Math.abs(info.total_amount) | money }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">Type:</v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.type | ucwords }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Remarks:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.remarks }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Destination:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.destination }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Source Type:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.source_type }}
        </v-col>
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Source ID:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.source_id }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">User:</v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.user_id }}
        </v-col>
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Company:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.company_id }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Cancelled On:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.cancelled_at | format('lll') }}
        </v-col>
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Approved On:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.approved_at | format('lll') }}
        </v-col>
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Released On:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.released_at | format('lll') }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">TXID:</v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.txid }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">For:</v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          {{ info.for }}
        </v-col>
      </v-row>
      <v-row dense align="start" justify="start">
        <v-col cols="3" class="secondary--text fw-700 text-right">
          Others Details:
        </v-col>
        <v-col cols="9" class="primary--text fw-500 pl-2">
          <v-row
            dense
            align="center"
            v-for="(prop, key) in info.props"
            :key="key"
            justify="start"
          >
            <v-col cols="12">
              <span class="fw-300"> {{ key }} </span> : {{ prop }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>

<script>
import _omit from 'lodash/omit'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'CreditView',
  components: { CustomDialog },
  data: () => ({
    open: false
  }),
  props: {
    item: Object,
    dialog: { type: Boolean, default: false }
  },
  computed: {
    info() {
      let val = _omit(this.item, 'company')
      val.company = (this.item && this.item.company.name) || ''
      return val
    }
  },
  watch: {
    dialog(val) {
      this.open = val
    },
    open(val) {
      this.$emit('update:dialog', val)
    }
  },
  methods: {
    close() {
      this.open = false
    }
  }
}
</script>

<style scoped></style>
