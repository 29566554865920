<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    title="Reject Withdrawal Request"
    button2-text="Submit"
    @button2="save"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-actions class="flex-column d-flex align-start fullwidth">
          <label class="subtitle-2 primary--text">Remarks/Reason</label>
          <v-radio-group column mandatory v-model="remarks">
            <v-radio
              value="Insufficient funds"
              label="Insufficient funds"
            ></v-radio>
            <v-radio
              value="Suspicious withdrawal request"
              label="Suspicious withdrawal request"
            ></v-radio>
            <v-radio
              value="Cancel request by owner"
              label="Cancel request by owner"
            ></v-radio>
            <v-radio
              value="Duplicate request or has already a pending request"
              label="Duplicate request or has already a pending request"
            ></v-radio>
            <v-radio value="Others" label="Others"></v-radio>
          </v-radio-group>
          <v-textarea
            v-if="remarks === 'Others'"
            hide-details="auto"
            dense
            class="fullwidth"
            outlined
            :rules="[requiredRule()]"
            v-model="remarks"
            auto-grow
            rows="3"
          ></v-textarea>
        </v-card-actions>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
export default {
  name: "RejectWithdrawal",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
  },
  data: () => ({
    dialog: false,
    remarks: "Others",
  }),
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    save() { 
      this.$emit("submit", { id: this.item.id, remarks: this.remarks });
    },
  },
};
</script>
 <style>
</style>