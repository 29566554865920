<template>
  <CustomDialog
    title="Add Transaction" 
    :main-btn-disabled="!valid || submitting"
    :open.sync="open"
    button2-text="Save"
    @button1="close"
    @button2="save"
  >
    <template v-slot:content>
      <v-form v-model="valid">
        <v-row dense>
          <v-col md="6">
            <label class="primary--text subtitle-2">Transaction Type</label>
            <v-select
              hide-details="auto"
              :rules="requiredRules"
              outlined
              dense
              :items="['credit', 'debit']"
              v-model="tx.type"
            ></v-select>
          </v-col>
          <v-col md="6">
            <label class="primary--text subtitle-2">Amount</label>
            <v-text-field
              hide-details="auto"
              :rules="numericRules"
              outlined
              dense
              v-model="tx.amount"
              placeholder="Enter amount"
              persistent-hint
              hint="Debit should be negative value, Credit should be positive value"
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <label class="primary--text subtitle-2">
              Transaction Recipient
            </label>
            <v-select
              hide-details="auto"
              item-text="name"
              item-value="id"
              :rules="requiredRules"
              outlined
              dense
              :items="companies"
              v-model="tx.company_id"
            ></v-select>
          </v-col>
          <v-col md="6">
            <label class="primary--text subtitle-2">For Transaction</label>
            <v-select
              hide-details="auto"
              item-text="name"
              item-value="value"
              :rules="requiredRules"
              outlined dense
              :items="[
                { name: 'SMS/MMS Credits', value: 'smsmms' },
                { name: 'Email Credits', value: 'email' },
              ]"
              v-model="tx.for"
            ></v-select>
          </v-col>
          <v-col md="12">
            <label class="primary--text subtitle-2">Remarks</label>
            <v-text-field
              hide-details="auto"
              outlined
              v-model="tx.remarks"
              placeholder="Enter remarks"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import request from "@/services/axios_instance";

export default {
  name: "CreditDialog",
  components: { CustomDialog },
  data: () => ({
    open: false,
    valid: false,
    tx: {
      amount: 0.0,
      type: "credit",
      company_id: null,
      remarks: null,
      for: "smsmms",
    },
    companies: [],
    submitting: false,
  }),
  props: {
    dialog: { type: Boolean, default: false },
  },
  watch: {
    dialog(val) {
      this.open = val;
      if (val) this.submitting = false;
    },
    open(val) {
      this.$emit("update:dialog", val);
    },
  },
  created() {
    request.get(`api/companies/subscribers`).then(({ data }) => {
      this.companies = data;
    });
  },
  methods: {
    close() {
      this.open = false;
    },
    save() {
      this.submitting = true;
      this.$emit("save", this.tx);
    },
  },
};
</script>

<style scoped>
</style>