<template>
  <v-card flat>
    <v-row dense class="pa-1">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="text-none mr-1"
        @click="open_credit_dialog = true"
        color="primary"
      >
        <v-icon left>mdi-plus-circle-outline</v-icon>
        <span>Add Transaction</span>
      </v-btn>
    </v-row>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :disable-pagination="true"
        :items="items"
        hide-default-footer
      >
        <template v-slot:[`item.created_at`]="{ value }">
          {{ value | format("lll") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="viewTransaction(item)">
            mdi-eye-circle-outline
          </v-icon>
        </template>
        <template v-slot:footer>
          <v-row dense class="pa-1">
            <v-spacer></v-spacer>
            <v-btn
              text
              class="text-none mt-2"
              outlined
              :disabled="!next_page_url || loading_more_items"
              :loading="loading_more_items"
              small
              @click="loadMoreItems"
            >
              Load more
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>

    <CreditDialog
      :dialog.sync="open_credit_dialog"
      @save="handleCreditSave"
    ></CreditDialog>
    <CreditView
      :dialog.sync="open_view_credit_dialog"
      :item="activeItem"
    ></CreditView>
  </v-card>
</template>

<script>
import { fetch_services } from "@/services/fetch/fetch_services";

import CreditDialog from "@/modules/AdminArea/PaymentGateway/components/CreditDialog.vue";
import CreditView from "@/modules/AdminArea/PaymentGateway/components/CreditView.vue";
export default {
  name: "Transactions",
  mixins: [fetch_services],
  components: {
    CreditDialog,
    CreditView,
  },
  created() {
    this.getTransactions();
  },
  data: () => ({
    open_credit_dialog: false,
    open_view_credit_dialog: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "TXID", value: "txid" },
      { text: "Type", value: "type" },
      { text: "For", value: "for" },
      { text: "Amount", value: "amount" },
      { text: "Date", value: "created_at" },
      { text: "Action", value: "actions" },
    ],
  }),
  methods: {
    handleCreditSave(payload) {
      this.addItem(
        `api/admin/transactions`,
        payload,
        "Successfully added transaction entry",
        () => {
          this.open_credit_dialog = false;
        },
        null
      );
    },
    getTransactions() {
      this.loadItems(`api/admin/transactions`, true);
    },
    viewTransaction(item) {
      this.activeItem = item;
      this.open_view_credit_dialog = true;
    },
  },
};
</script>

<style>
</style>